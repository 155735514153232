.loaderCover {
	background: #00000047;
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99;
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(24px, 0);
	}
}

/* 
Code For Loader In Registration Page
*/

.lds-roller {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
}

.lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: rgb(0 132 205);
	margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
	top: 63px;
	left: 63px;
}

.lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
	top: 68px;
	left: 56px;
}

.lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
	top: 71px;
	left: 48px;
}

.lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
	top: 72px;
	left: 40px;
}

.lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
	top: 71px;
	left: 32px;
}

.lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
	top: 68px;
	left: 24px;
}

.lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
	top: 63px;
	left: 17px;
}

.lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
	top: 56px;
	left: 12px;
}

@keyframes lds-roller {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}